import React from "react"
import cx from "classnames"

import { makeStyles } from "@material-ui/styles"

import "./layout.css"
import "./animate.min.css"

import land from "../images/land.jpg"
import sport from "../images/sport_track.jpg"

import { library } from "@fortawesome/fontawesome-svg-core"
import {
  faCheckSquare,
  faChevronRight,
  faChevronLeft,
} from "@fortawesome/free-solid-svg-icons"

library.add(faCheckSquare, faChevronRight, faChevronLeft)

const useStyle = makeStyles({
  home: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },

  baseStyle: {
    backgroundColor: "whitesmoke",
    backgroundSize: "cover",
    height: "100vh",
  },
})

const Layout = ({ children, page }) => {
  const classes = useStyle()

  let backgroundImage
  switch (page) {
    case "home":
      backgroundImage = `linear-gradient( rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3) ), url(${land})`
      break
    case "aboutUs":
      backgroundImage = `linear-gradient( rgba(0, 0, 0, 0.93), rgba(0, 0, 0, 0.3) ), url(${sport})`
      break
    default:
      break
  }

  return (
    <div
      style={{ backgroundImage }}
      className={cx([page ? classes[page] : undefined, classes.baseStyle])}
    >
      {children}
    </div>
  )
}

export default Layout
